<template>
    <nav>
        <ul>
            <li>
                <img src="../assets/img/logominiblanco.svg" alt="" title="">
                <h2>Dashboard</h2>
            </li>
            <hr>
            <li>
                <a href="/dashboard"><img src="../assets/img/dashboard.svg" alt="" title="">
                    <p> Dashboard</p>
                </a>
            </li>
            <li>
                <a href="/dashboard/editar"><img src="../assets/img/edit.svg" alt="" title="">
                    <p> Editar Perfil</p>
                </a>
            </li>
            <li>
                <a href="/dashboard/historial"><img src="../assets/img/history.svg" alt="" title="">
                    <p> Historial</p>
                </a>
            </li>
            <li>
                <a href="#"><img src="../assets/img/logout.svg" alt="" title="">
                    <p> Salir</p>
                </a>
            </li>
        </ul>
    </nav>
</template>